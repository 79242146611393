import ScrollMagic from "scrollmagic";
import { gsap, TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import slick from 'slick-carousel';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

$(function(){
  var pace = (function(){

    'use strict';

    var d = {};
    var s = {};
    var c = {};

    s.target = '.pace';
    s.targetProgress = s.target + '-progress';
    s.targetActivity = s.target + '-activity';
    s.hide = s.target + '-hide';

    $.each(s, function(k, v){

      if(v.match(/^\./)) c[k] = v.substr(1);
    });

    init();

    function init(){
      d.active = false;

      Pace.on('start', function(){
         if(!d.active) d.active = setObject();
      });

      Pace.on('done', function(){
        $('.pace-btn').removeAttr('disabled');
      });

      //android Firefoxは、キャッシュがある場合、Paceの startが実行されない
      d.tid = setInterval(function(){
         if($(s.target).length){
           clearInterval(d.tid);
           if(!d.active) d.active = setObject();
        }
      }, 100);
    }//pace.init()

    function setObject(){
      d.$pace = $('.pace').addClass(d.transition).append('<div class="pace-text">Are you ready? </div><button class="pace-btn flash" disabled="disabled">Yes.</button>');
      d.$paceProgress = $(s.targetProgress);
      d.$paceActivity = $(s.targetActivity);
      d.$logo = d.$paceActivity.append('<div class="' + [c.hide].join(' ') + '"></div></div>');

      return true;
    };//pace.setObject()
  }());//pace()
});

$(function () {
  $(document).on('click', '.pace-btn', function () {
    slick_start();
    opening_start();
    bodyAnimationStart();
  });
});

// ブラウザリロード時にスクロールをページトップにもってくる
$(function () {
  $('html, body').animate({ scrollTop: 0 }, '1');
});

// opening アニメーション
function opening_start() {
  var opening = new TimelineMax();

  $('.loading, .pace').fadeOut();
  $('.header, .article, .footer__wrap').fadeIn();

  opening.from('.header', .7, {
    left: '-100%',
    opacity: 0
  }).from('.volume_control, .language_switch', .7, {
    right: '-100%',
    opacity: 0
  }).from('.sec01__box img', .7, {
    x: '100%'
  }).from('.fixed_logo .logo02', .7, {
    bottom: '-100%',
    opacity: 0
  }).from('.fixed_logo .logo03', .7, {
    bottom: '-100%',
    opacity: 0
  }, '-=.7' ).from('.fixed_arrow .ico_arrow01', .5, {
    top: '-100%',
    opacity: 0
  }).to('.fixed_arrow .ico_arrow01', .5, {
    opacity: 0,
  }).to('.fixed_arrow .ico_arrow01', .5, {
    opacity: 1,
  })
}

var sec_num = $('.slider_sec__wrap section.slider_sec').length;

var sec02__box = new TimelineMax();

function slick_start() {
  $('.js_sec_slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    autoplay: true,
    speed: 1000,
    dots: false,
    arrows: false,
    draggable: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    fade: true
  }).on({
    beforeChange: function (event, slick, currentSlide, nextSlide) {
      if (currentSlide + 2 > sec_num) {
        var current = 1;
      } else {
        var current = currentSlide + 2;
      }
      if ((current % 2) != 0) {
        var pos = '-100%';
      } else {
        var pos = '100%';
      }
      if (current == 2) {
        sec02__box.from(".slick-slide .sec0" + current + "__box .img_sec02_01", .7, {
          x: '-100%',
          delay: 0.5
        }).from(".slick-slide .sec0" + current + "__box .img_sec02_02", .7, {
          x: '100%',
        }, '-=.7' );
      } else {
        TweenMax.from(".slick-slide .sec0" + current + "__box img", .7, {
          x: pos,
          delay: 0.5
        });
      }
    }
  });
};

function bodyAnimationStart() {
  var controller = new ScrollMagic.Controller();

  /* ---- sec05.setPin ----*/
  new ScrollMagic.Scene({
    triggerElement: '.sec05',
    duration: 500,
    triggerHook: "onLeave"
  })
    .setPin('.sec05')
    .addTo(controller);

  /*---- sec06 animation ----*/
  var sec06 = new TimelineMax();

  sec06.from(".sec06 .img_sec06_01", .7, {
    x: '100%'
  }).from(".sec06__anime02", .5, {
    x: '-100%',
    opacity: 0
  }, '-=.7' ).from(".sec06__anime01", .5, {
    x: '-100%',
    opacity: 0
  }, '-=.7').from(".sec06__anime03", .5, {
    x: '100%',
    opacity: 0
  });

  new ScrollMagic.Scene({
      triggerElement: ".sec06",
      triggerHook: '.5',
    })
    .setTween(sec06)
    // .addIndicators({ name: "sec02"})
    .addTo(controller);

  /* ---- sec06__phase01 .setPin ----*/
  new ScrollMagic.Scene({
    triggerElement: '.sec06__phase01',
    duration: 500,
    triggerHook: "onLeave"
  })
    .setPin('.sec06__phase01')
    .addTo(controller);

  /*---- sec06__phase2 animation ----*/
  var sec06__phase2 = new TimelineMax();

  sec06__phase2.from(".sec06 .img_sec06_03", .7, {
    x: '100%',
    opacity: 0
  }).from(".sec06 .img_sec06_02", .7, {
    x: '-100%',
    opacity: 0
  }).from(".sec06__anime05", .5, {
    x: '-100%',
    opacity: 0
  }, '-=.5' ).from(".sec06__anime04", .5, {
    x: '-100%',
    opacity: 0
  }, '-=.5').from(".sec06__anime06", .5, {
    x: '100%',
    opacity: 0
  }, '-=.5');

  new ScrollMagic.Scene({
      triggerElement: ".sec06__phase02",
      triggerHook: '.25',
    })
    .setTween(sec06__phase2)
    // .addIndicators({ name: "sec02"})
    .addTo(controller);

  /* ---- sec06__phase02 .setPin ----*/
  new ScrollMagic.Scene({
    triggerElement: '.sec06__phase02',
    duration: 500,
    triggerHook: "onLeave"
  })
    .setPin('.sec06__phase02')
    .addTo(controller);

  /*---- sec06__phase2 animation ----*/
  var sec06__centerBox02 = new TimelineMax();

  sec06__centerBox02.from(".sec06 .img_sec06_05 img", .7, {
    x: '100%'
  }).from(".sec06 .img_sec06_04 img", .7, {
    x: '-100%'
  });

  new ScrollMagic.Scene({
      triggerElement: ".sec06__centerBox02",
      triggerHook: '.5',
    })
    .setTween(sec06__centerBox02)
    // .addIndicators({ name: "sec02"})
    .addTo(controller);

  /* ---- sec06__phase02 float ----*/
  let box = $('.sec06__float01, .sec06__float02, .sec06__float03'),
  boxWrapper = $('.sec06__phase02');
  var offset = 550;
  var boxWrapperBottom = 0,
    boxHeight = 0,
    boxWrapperHeight = 0,
    boxWrapperTop = 0;

  init();

  function init() {
    boxHeight = box.innerHeight(),
    boxWrapperHeight = boxWrapper.innerHeight(),
    boxWrapperTop = boxWrapper.offset().top,
    boxHeight = box.innerHeight();
    boxWrapperBottom = boxWrapperTop + boxWrapperHeight;
  }

  var timer = setInterval(function () {
    if(boxWrapperTop == 0) {
      boxWrapperTop = boxWrapper.offset().top;
    } else {
      clearInterval(timer);
      init();
    }
  }, 200);

  $(window).on('load scroll', function() {
    let value = $(this).scrollTop();
    // 通り過ぎた後
    if (value > boxWrapperBottom - boxHeight + offset) {
      box.css({
        'position': 'absolute'
      });
    // 通過中
    } else if ( value > boxWrapperTop) {
      box.css({
        'position': 'fixed'
      });
    // 通り過ぎる前
    } else {
      box.css({
        'position': 'absolute'
      });
    }
  });

  /* ---- sec06__phase02 .setPin ----*/
  new ScrollMagic.Scene({
    triggerElement: '.sec06__centerBox02',
    duration: 500,
    triggerHook: "onLeave"
  })
    .setPin('.sec06__centerBox02')
    .addTo(controller);

  /*---- sec06_leave animation ----*/
  var sec06_leave = new TimelineMax();

  sec06_leave.to(".sec06__leftTxt02", .5, {
    x: '-100%',
    opacity: 0
  }, '-=.5' ).to(".sec06__leftTxt01", .5, {
    x: '-100%',
    opacity: 0
  }, '-=.5').to(".sec06__rightTxt", .5, {
    x: '100%',
    opacity: 0
  }).from(".sec_contact__leftTxt01", .5, {
    x: '-100%',
    opacity: 0
  }).from(".sec_contact__rightTxt", .5, {
    x: '100%',
    opacity: 0
  });

  new ScrollMagic.Scene({
      triggerElement: ".sec_contact",
      triggerHook: 'onEnter',
    })
    .setTween(sec06_leave)
    // .addIndicators({ name: "sec02"})
    .addTo(controller);

  /* ---- sec_contact .setPin ----*/
  new ScrollMagic.Scene({
    triggerElement: '.sec_contact',
    duration: 500,
    triggerHook: "onLeave"
  })
    .setPin('.sec_contact')
    .addTo(controller);
}

