import "../scss/style.scss";
import "./volume.js";
import "./contactForm.js";
import "./animation.js";
// import "./youtube.js";

// smooth scroll
$(function () {
	$('a[href^="#"]').click(function () {
		var headerH = 0;
		var href = $(this).attr("href");
		var target = $(href == "#" || href == "" ? "body" : href);
		var position = target.offset().top - headerH;
		$("html, body").animate({ scrollTop: position }, 800, "swing");
		return false;
	});
});


//スクロールされたらフェードイン & アップ
$(window).scroll(function () {
	$('.fade-in, .fade-up').each(function () {
		var elemPos = $(this).offset().top;
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll > elemPos - windowHeight + windowHeight / 7) {
			$(this).addClass('scrollin');
		}
		// else {
		// 		$(this).removeClass('scrollin');
		// }
	});
});


// スマホ高さ100vh調整
$(function () {
	let vh = document.documentElement.clientHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


$(function () {
  $('.nav_btn').on('click', function () {
    if (!$('.nav').hasClass('open')) {
      $(".nav").addClass('open');
      $(this).addClass('open');
      setTimeout(function () {
        $(".nav_list").addClass('open');
      }, 300);
    } else {
			$(this).removeClass('open');
      $(".nav_list").removeClass('open');
      setTimeout(function () {
        $(".nav").removeClass('open');
      }, 300);
    }
  });
  $('.nav_list a[href^="#"]').on('click', function() {
    $('.nav_btn').removeClass('open');
    $('.nav_list').removeClass('open');
    setTimeout(function () {
      $('.nav').removeClass('open');
    }, 300);
  });
});

