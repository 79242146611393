// ON 押下時
$('.volume_control__list__item.is_on a').on('click', function () {

  var autoplay = setInterval(function(){
    if(bg_video.readyState === 4) {
      bg_video.play();
      clearInterval(autoplay);
    }
  }, 200);

  bg_video.muted = false;
  $('.volume_control__list__item a').removeClass('active');
  $(this).addClass('active');
  let timerid = setInterval(() => {
    if ((bg_video.volume + 0.1) >= 1) {
      bg_video.volume = 1;
      clearInterval(timerid);
    }
    else {
      bg_video.volume += 0.1;
    }
  }, 200);
});

// OFF 押下時
$('.volume_control__list__item.is_off a').on('click', function() {
  bg_video.muted = true;
  $('.volume_control__list__item a').removeClass('active');
  $(this).addClass('active');
  let timerid = setInterval(() => {
    if ((bg_video.volume - 0.1) <= 0) {
      bg_video.volume = 0;
      clearInterval(timerid);
    }
    else {
      bg_video.volume -= 0.1;
    }
  }, 200);
});
