$(document).on("click", "#confirmBtn", function () {
  if (!$('form')[0].reportValidity()) {
    return false;
  }
  $('.sec_contact__box__item.is_button').html('<label></label><button type="button" id="backBtn">戻る</button><button type="submit">送信する</button>');
  $('.sec_contact__box__item').addClass('is_confirm');
  $('.sec_contact__box__item input, .sec_contact__box__item textarea').attr('readonly', true);
  $('.sec_contact__box__item select').attr('disabled', true);
  $('.sec_contact__box__message').html('下記の内容で送信して宜しいですか？');
});


$(document).on("click", "#backBtn", function () {
  $('.sec_contact__box__item.is_button').html('<label></label><button type="button" id="confirmBtn">送信確認</button>');
  $('.sec_contact__box__item').removeClass('is_confirm');
  $('.sec_contact__box__item input, .sec_contact__box__item textarea').attr('readonly', false);
  $('.sec_contact__box__item select').attr('disabled', false);
  $('.sec_contact__box__message').empty();
});


$('.contactForm').on('submit', function (event) {
  $('.sec_contact__box__item select').attr('disabled', false);

  //submit実行を止める
  event.preventDefault();
  var $form = $(this);
  var $button = $form.find('button[type=submit]');
  $.ajax({
    url:$form.attr('action'),
    type:$form.attr('method'),
    data: $form.serialize(),
    //10秒でタイムアウト
    timeout:10000,
    //重複送信を避けるためにボタンを無効化
    beforeSend: function(xhr, settings) {
        $button.attr('disabled', true);
    },
    //完了後ボタンを押せるように
    complete: function(xhr, textStatus) {
        $button.attr('disabled', false);
    }
  }).done(function(data, textStatus, jqXHR){
    // 成功時の処理
    //フォームを初期化
    $form[0].reset();
    $(".sec_contact__box").html('<p class="sec_contact__box__text">お問い合わせ有難うございます<br><br><span>お問い合わせ頂いた内容については、確認の上、ご返信させていただきます。<br>今しばらくお待ちくださいますようお願いいたします。</span></p>');
  }).fail(function(jqXHR, textStatus, errorThrown){
    // 失敗時の処理
    $(".sec_contact__box__message").html("エラーが発生しました。ステータス：" + jqXHR.status);
  });
});
